<template>
	<div>
		<div class="grid grid-cols-9 gap-5 p-5 border mx-20 mt-10 mb-5">
			<div class="col-span-1">
				<input ref="sku" v-model="sku" type="text" :class="{'bg-gray-200': isDisabled}" class="h-9 px-5 border w-full rounded" maxlength="5" :disabled="isDisabled">
			</div>
			<div class="col-span-5">
				<input v-model="product.name" type="text" :class="{'bg-gray-200': isDisabled}" class="h-9 px-5 border w-full rounded" :disabled="isDisabled" readonly>
			</div>
			<div class="col-span-2">
				<auto-numeric @keydown.enter.native="onEnterPrice" ref="price" :class="{'bg-gray-200': isDisabled}" v-model="product.unitPrice" class="h-9 px-5 text-right border rounded w-full" :disabled="isDisabled"></auto-numeric>
			</div>
			<div class="col-span-1">
				<auto-numeric ref="qty" @keydown.enter.native="onEnterQty" v-model="product.qty" :class="{'bg-gray-200': isDisabled}" class="h-9 px-5 text-right border rounded w-full" :disabled="isDisabled"></auto-numeric>
			</div>
		</div>
		<div class="mx-20 my-5 flex justify-between items-center">
			<input type="text" class="h-9 px-5 border rounded-sm">
			<div class="flex space-x-10">
				<div>QTY: {{Intl.NumberFormat().format(totalQty)}}</div>
				<div>Rp: {{Intl.NumberFormat().format(totalPrice)}}</div>
			</div>
		</div>
		<div class="mx-20 mb-20 mt-5 rounded overflow-hidden">
			<table class="w-full">
				<thead>
					<th class="p-3 bg-blue-500 text-blue-50 text-left">SKU</th>
					<th class="p-3 bg-blue-500 text-blue-50 text-left">Nama</th>
					<th class="p-3 bg-blue-500 text-blue-50 text-right">Harga</th>
					<th class="p-3 bg-blue-500 text-blue-50 text-right">Qty</th>
					<th class="p-3 bg-blue-500 text-blue-50 text-right">Total</th>
					<th class="p-3 bg-blue-500 text-blue-50"></th>
				</thead>
				<tbody class="border divide-y">
					<tr v-for="(item, i) in itemLists" :key="i">
						<td class="p-3">{{item.sku}}</td>
						<td class="p-3">{{item.name}}</td>
						<td class="p-3 text-right">{{Intl.NumberFormat().format(item.unitPrice)}}</td>
						<td class="p-3 text-right">{{item.qty}}</td>
						<td class="p-3 text-right">{{Intl.NumberFormat().format(item.total)}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import AutoNumeric from './components/AutoNumeric.vue'
export default {
	components: {AutoNumeric},
	data () {
		return {
			isDisabled: false,
			sku: '',
			product: {
				mitraId: '',
				productId: '',
				sku: '',
				name: '',
				unitPrice: '',
				onHand: '',
				qty: '',
			},
			items: []
		}
	},
	computed: {
		itemLists () {
			let itm = this.items.map(obj => {
				obj.total = obj.unitPrice * obj.qty
				return obj
			})
			return itm.reverse()
		},
		totalQty () {
			let sum = 0;
			for (let i = 0; i < this.items.length; i++) {
				sum += this.items[i].qty
			}
			return sum
		},
		totalPrice () {
			let sum = 0;
			for (let i = 0; i < this.itemLists.length; i++) {
				sum += this.itemLists[i].total
			}
			return sum
		}
	},
	methods: {
		getSku () {
			axios.get('/mitra-api/so/sku', {
				params: {
					sku: this.sku
				}
			})
			.then(res => {
				this.product.productId = res.data._id
				this.product.sku = res.data.sku
				this.product.name = res.data.name
				this.product.unitPrice = res.data.unitPrice
				this.product.qty = ''
				this.product.onHand = res.data.qty
				this.$nextTick(() => {
					this.$refs.price.$el.focus()
				})
			})
		},
		onEnterPrice () {
			if(this.product.unitPrice) {
				this.$refs.qty.$el.focus()
			}
		},
		onEnterQty () {
			console.log(typeof this.product.qty)
			if(this.product.unitPrice && typeof this.product.qty == 'number') {
				this.isDisabled = true
				this.product.mitraId = '6576cbf22e67868bd1d32ba5'
				axios.post('/mitra-api/so', this.product)
				.then(() => {
					this.isDisabled = false
					this.items.push(this.product)
					this.sku = ''
					this.product  = {
						mitraId: '',
						productId: '',
						sku: '',
						name: '',
						unitPrice: '',
						qty: '',
						onHand: ''
					}
					this.$nextTick(() => {
						this.$refs.sku.focus()
					})
				})
			}
		}
	},
	watch: {
		sku (val) {
			if(val.length == 5) {
				this.getSku()
			}
		}
	}
}
</script>